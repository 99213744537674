import { useRequest } from "ahooks";
import {
  Flex,
  Spacer,
  Button,
  useDisclosure,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  CircularProgress,
  Td,
  TableCaption,
  Box,
} from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "./AuthContextProvider";
import {
  ISnowPicture,
  ISnowPictureWithTitle,
  TimeHash,
  Operations,
  ContentsTitleKeyHash,
  SystemConst,
  returnStringValue,
  periodFormatText,
  nowDateYMDHM_Szero,
} from "./SystemConst";
import { ListRefreshButton } from "./partial/ListRefreshButton";
import { InputContentsTitle } from "./partial/InputContentsTitle";
import { SnowPictureModalRegForm } from "./partial/SnowPictureModalRegForm";

const timeBgColorHash = {
  T12: "gray.50",
  T24: "green.50",
  T72: "orange.50",
};

export function SnowPictureList() {
  const apiPath = "/api/snow-picture";
  const [user] = useContext(UserContext);
  if (!user) {
    throw Error("user is not null");
  }
  const signInUser = user;
  // アカウントが Admin権限（WNI）かどうか
  const isAdminDepartment: boolean =
    signInUser.department === SystemConst.AdminDepartment ? true : false;

  const [department] = useState(signInUser.department);
  const [person] = useState(signInUser.person);

  /**
   * 一覧取得
   */
  const { data, loading, refresh } = useRequest<ISnowPictureWithTitle, []>(
    (): Promise<ISnowPictureWithTitle> =>
      fetch(`${apiPath}/withtitleNoPeriod`).then((x) => x.json()),
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  /**
   * 登録
   */
  const { run: createImgUpload } = useRequest(
    (params: {
      images: string[];
      time: string;
      edittype: string;
      fromDate: Date;
      toDate?: Date;
    }): Promise<any> =>
      fetch(`${apiPath}/multiwithhistory`, {
        method: "POST",
        body: JSON.stringify({
          images: params.images,
          time: params.time,
          edittype: params.edittype,
          fromDate: params.fromDate,
          toDate: params.toDate,
          department: department,
          person: person,
          operation: Operations.CREATE,
        }),
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": signInUser.token,
        },
      }).then(refresh),
    { manual: true },
  );

  /**
   * 更新（１件）
   */
  const { run: editImgUpdate } = useRequest(
    (
      id: number,
      params: {
        images: string[];
        time: string;
        edittype: string;
        fromDate: Date;
        toDate?: Date;
      },
    ): Promise<any> =>
      fetch(`${apiPath}/withhistory/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          content: params.images[0],
          time: params.time,
          edittype: params.edittype,
          fromDate: params.fromDate,
          toDate: params.toDate,
          department: department,
          person: person,
          operation: Operations.EDIT,
        }),
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": signInUser.token,
        },
      }).then(refresh),
    { manual: true },
  );

  const requestFunc = async (
    updateId: number,
    //    params: { images: string[]; time: string; edittype: string }
    params: {
      images: string[];
      time: string;
      edittype: string;
      fromDate: Date;
      toDate?: Date;
    },
  ) => {
    if (updateId) {
      await editImgUpdate(updateId, params);
    } else {
      await createImgUpload(params);
    }
  };

  /**
   * 削除
   */
  const { run: removeWithHistory } = useRequest(
    (id: number): Promise<void> =>
      fetch(`${apiPath}/withhistory/${id}`, {
        method: "DELETE",
        body: JSON.stringify({
          operation: Operations.DELETE,
          department: department,
          person: person,
        }),
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": signInUser.token,
        },
      }).then(refresh),
    { manual: true },
  );

  /**
   * コンテンツタイトル編集
   */
  const [contentsTitle, setContentsTitle] = useState("");
  useEffect(() => {
    if (data?.title) {
      setContentsTitle(returnStringValue(data?.title));
    }
  }, [data]);
  const { run: updateTitle } = useRequest(
    (): Promise<any> =>
      fetch(`/api/contents-title/withhistory/${ContentsTitleKeyHash["IMG"]}`, {
        method: "PUT",
        body: JSON.stringify({
          idType: ContentsTitleKeyHash["IMG"],
          title: contentsTitle,
          department: user.department,
          person: user.person,
          operation: Operations.EDIT,
        }),
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": signInUser.token,
        },
      }).then((x) => x.json()),
    { manual: true },
  );

  const showContentsTitleElement = () => {
    return isAdminDepartment ? (
      <InputContentsTitle
        param={{
          titleText: "画像",
          contentsTitle: contentsTitle,
          inputMaxLen: 12,
          func_setContentsTitle: setContentsTitle,
          func_updateTitle: updateTitle,
        }}
      />
    ) : (
      <></>
    );
  };

  /**
   * 登録 / 更新 Modal 初期画像 設定
   */
  const [isMultiMode, setIsMultiMode] = useState(false);
  const initImgUploadProps: ISnowPicture = {
    id: 0, // Create:0
    time: "T12",
    content: "",

    //fromDate: new Date(),
    fromDate: nowDateYMDHM_Szero(),

    toDate: undefined,
  };
  const [imgUploadProps, setImgUploadProps] =
    useState<ISnowPicture>(initImgUploadProps);
  const handleOnOpen = (multiMode: boolean, pa: ISnowPicture) => {
    // set local lacale
    pa.fromDate = new Date(pa.fromDate);
    pa.toDate = pa.toDate ? new Date(pa.toDate) : pa.toDate;
    setIsMultiMode(multiMode);
    setImgUploadProps(pa);
    onOpen();
  };
  /**
   * 掲載期間 Format文字列
   * @param rowData
   * @returns
   */
  const validPriod = (rowData: ISnowPicture) => {
    const res = periodFormatText(rowData.fromDate, rowData.toDate);
    return (
      <>
        <Box color={res.colorState}>{res.fromDateFormat} 〜</Box>
        <Box color={res.colorState}>{res.toDateFormat}</Box>
      </>
    );
  };

  if (loading) {
    return <CircularProgress isIndeterminate color="green.300" />;
  }
  return (
    <div>
      <Flex>
        <Button
          mx={4}
          colorScheme="blue"
          onClick={() => handleOnOpen(true, initImgUploadProps)}
        >
          作成
        </Button>
        <Spacer />
        {showContentsTitleElement()}
        <Spacer />
        <ListRefreshButton
          func={async () => {
            await refresh();
          }}
        />
      </Flex>
      <Table variant="simple" size="sm">
        <TableCaption>画像 リスト</TableCaption>
        <Thead>
          <Tr>
            <Th p={2}>画像</Th>
            <Th p={2}>予測時間</Th>
            <Th p={2}>掲載期間</Th>
            <Th p={2}>編集</Th>
            <Th p={2}>削除</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.list.map((i: ISnowPicture) => (
              <Tr key={i.id} bgColor={timeBgColorHash[i.time]}>
                <Td p={2}>
                  <Box>
                    <Image src={i.content} w={300} alt="" />
                  </Box>
                </Td>
                <Td p={2}>{TimeHash[i.time]}</Td>
                <Td p={2}>{validPriod(i)}</Td>
                <Td p={2}>
                  <Button
                    size="xs"
                    onClick={() => {
                      handleOnOpen(false, i);
                    }}
                    colorScheme="teal"
                    variant="ghost"
                  >
                    編集
                  </Button>
                </Td>
                <Td p={2}>
                  <Button
                    size="xs"
                    onClick={async () => {
                      if (window.confirm("本当に削除してもよいですか")) {
                        await removeWithHistory(i.id);
                        //await refresh();
                      }
                    }}
                    colorScheme="teal"
                    variant="ghost"
                  >
                    削除
                  </Button>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <SnowPictureModalRegForm
        param={{
          props: imgUploadProps,
          isMulti: isMultiMode,
          isOpenFlag: isOpen,
          funcUploadImg: requestFunc,
          funcClose: onClose,
          funcRefresh: refresh,
        }}
      />
    </div>
  );
}
