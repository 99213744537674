import { useRequest } from "ahooks";
import {
  Flex,
  Spacer,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  Input,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  CircularProgress,
  Td,
  TableCaption,
  Spinner,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  Box,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import { useState, useContext } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { UserContext } from "./AuthContextProvider";
import {
  SystemConst,
  AreaTagetNames,
  DepartmentOptionAry,
  RoleOptionAry,
  StatusOptionAry,
} from "./SystemConst";
import { ListRefreshButton } from "./partial/ListRefreshButton";

interface FormInputs {
  id: number;
  email: string;
  password: string;
  person: string;
  department: string;
  role: string;
  status: string;
}

interface User {
  id: number;
  email: string;
  password: string;
  person: string;
  department: string;
  role: string;
  status: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export function UserList() {
  const apiPath = "/api/users";
  const [user, setUser] = useContext(UserContext);
  if (!user) {
    throw Error("user is not null");
  }
  const signInUser = user;

  const [id, setId] = useState(0);
  const [isEditAction, setIsEditAction] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const {
    isOpen: isOpenPwd,
    onOpen: onOpenPwd,
    onClose: onClosePwd,
  } = useDisclosure();
  /**
   * 一覧取得
   */
  const { data, loading, refresh } = useRequest<User[], []>(
    (): Promise<User[]> =>
      fetch(`${apiPath}/department/${signInUser.department}`, {
        method: "GET",
        headers: { "Custom-Auth-Token": signInUser.token },
      }).then((x) => x.json()),
  );

  /**
   * 削除
   */
  const { run: remove } = useRequest(
    (id: number): Promise<void> =>
      fetch(`${apiPath}/${id}`, {
        method: "DELETE",
        headers: { "Custom-Auth-Token": signInUser.token },
      }).then(refresh),
    { manual: true },
  );

  const formOpen = (rowData?: User) => {
    reset();
    if (rowData) {
      //console.info(rowData.id)
      setValue("email", rowData.email, {
        shouldValidate: false,
        shouldDirty: false,
      });
      setValue("person", rowData.person, {
        shouldValidate: false,
        shouldDirty: false,
      });
      setValue("department", rowData.department, {
        shouldValidate: false,
        shouldDirty: false,
      });
      setValue("role", rowData.role, {
        shouldValidate: false,
        shouldDirty: false,
      });
      setValue("status", rowData.status, {
        shouldValidate: false,
        shouldDirty: false,
      });
    }
    onOpen();
  };

  const [showPwd, setShowPwd] = useState(false);
  const handleClick = () => setShowPwd(!showPwd);
  const formPwdInput = () => {
    return isEditAction ? (
      // EDIT
      <></>
    ) : (
      // CREATE
      <FormControl
        id="password"
        isRequired
        isInvalid={errors.password ? true : false}
        mt={4}
      >
        <FormLabel htmlFor="password">Password</FormLabel>
        <InputGroup>
          <Input
            type={showPwd ? "text" : "password"}
            pr="4.5rem"
            placeholder="Enter password"
            autoComplete="new-password"
            {...register("password", {
              required: true,
              minLength: {
                value: 8,
                message: "8文字以上入力してください",
              },
              maxLength: {
                value: 16,
                message: "文字数が超過しています",
              },
            })}
            maxLength={16}
          />
          <InputRightElement width="4.5rem">
            <Button size="sm" onClick={handleClick}>
              {showPwd ? "Hide" : "Show"}
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>
          {errors.password && errors.password.message}
        </FormErrorMessage>
      </FormControl>
    );
  };

  const departmentOptions = (dep: string) => {
    if (dep === SystemConst.AdminDepartment) {
      return DepartmentOptionAry;
    } else {
      return [{ name: AreaTagetNames[dep], value: dep }];
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
    reset,
    setValue,
  } = useForm<FormInputs>({
    mode: "onSubmit",
  });

  // 送信
  const onSubmit = async (data: FormInputs) => {
    if (isEditAction) {
      axios({
        method: "PUT",
        url: `${apiPath}/${id}`,
        data: data,
        headers: { "Custom-Auth-Token": signInUser.token },
      })
        .then((response) => {
          onClose();
          refresh();

          // 自分自身の更新の場合
          if (signInUser.id === id.toString()) {
            //console.info("self deta change")
            // 更新情報をセットする
            // 但し、「Sign Out ボタン」のキャプションが変わらない => うまくいかない
            const editUser = signInUser;
            editUser.email = data.email;
            editUser.person = data.person;
            editUser.department = data.department;
            editUser.role = data.role;
            setUser(editUser);
            /*
            注）WNIのAdmin権限者限定になるが、
              自身の department を変更した場合は、再ログインして欲しい
            */
          }
        })
        .catch((error) => {
          console.log(error.status);
          alert("更新に失敗しました");
        });
    } else {
      axios({
        method: "POST",
        url: `${apiPath}`,
        data: data,
        headers: { "Custom-Auth-Token": signInUser.token },
      })
        .then((res) => {
          onClose();
          refresh();
        })
        .catch((error) => {
          axios({
            method: "GET",
            url: `${apiPath}/findDupUser/${data.email}`,
          })
            .then((res) => {
              if (res.data.email !== "") {
                alert(
                  "作成に失敗しました。このIDは既に使用されています\r\n他のIDを指定してください",
                );
              } else {
                alert("作成に失敗しました");
              }
            })
            .catch((e) => {
              alert("作成処理に失敗しました");
            });
        });
    }
  };

  const [showNewPwd, setShowNewPwd] = useState(false);
  const handleClickNewPwd = () => setShowNewPwd(!showNewPwd);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const changeNewPassword = () => {
    if (!newPassword) {
      setNewPasswordError("Passwordを入力してください");
      return false;
    }
    if (newPassword.length < 8) {
      setNewPasswordError("8文字以上入力してください");
      return false;
    }
    const sendData = {
      password: newPassword,
    };
    axios({
      method: "PUT",
      url: `${apiPath}/updatePassword/${id}`,
      data: sendData,
      headers: { "Custom-Auth-Token": signInUser.token },
    })
      .then((response) => {
        setNewPasswordError("");
        setNewPassword("");
        onClosePwd();

        toast({
          title: "Success! Password Changed.",
          //description: "Something message",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.log(error.status);
        alert("変更に失敗しました");
      });
  };

  if (loading) {
    return <CircularProgress isIndeterminate color="green.300" />;
  }
  return (
    <div>
      <Flex>
        {signInUser.role === SystemConst.UserTypeAdmin ? (
          <Button
            mx={4}
            colorScheme="blue"
            onClick={async () => {
              setIsEditAction(false);
              setId(0);
              formOpen(undefined);
            }}
          >
            作成
          </Button>
        ) : (
          <></>
        )}
        <Spacer />
        <ListRefreshButton
          func={async () => {
            await refresh();
          }}
        />
      </Flex>
      <Table variant="striped" colorScheme="blackAlpha" size="xs" mt={4}>
        <TableCaption>ユーザーリスト</TableCaption>
        <Thead>
          <Tr>
            <Th pl={2}>社名</Th>
            <Th>ID(メールアドレス)</Th>
            <Th>担当者名</Th>
            <Th>権限</Th>
            <Th>状態</Th>
            <Th>編集</Th>
            <Th>
              {signInUser.role === SystemConst.UserTypeAdmin ? "削除" : ""}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.map((i: User) => (
              <Tr key={i.id}>
                <Td pl={2}>{AreaTagetNames[i.department]}</Td>
                <Td>{i.email}</Td>
                <Td>{i.person}</Td>
                <Td>{i.role}</Td>
                <Td>{i.status}</Td>
                <Td>
                  {signInUser.role === SystemConst.UserTypeAdmin ||
                  Number(signInUser.id) === i.id ? (
                    <>
                      <Button
                        size="sm"
                        colorScheme="teal"
                        variant="ghost"
                        onClick={async () => {
                          setIsEditAction(true);
                          setId(i.id);
                          formOpen(i);
                          refresh();
                        }}
                      >
                        編集
                      </Button>
                      <Button
                        size="sm"
                        colorScheme="teal"
                        variant="ghost"
                        onClick={async () => {
                          setId(i.id);
                          setNewPasswordError("");
                          setNewPassword("");
                          setShowNewPwd(false);
                          onOpenPwd();
                        }}
                      >
                        PWD変更
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </Td>
                <Td>
                  {signInUser.role === SystemConst.UserTypeAdmin &&
                  Number(signInUser.id) !== i.id ? (
                    <Button
                      size="sm"
                      colorScheme="teal"
                      variant="ghost"
                      onClick={async () => {
                        if (
                          window.confirm("[ " + i.person + " ] を削除します")
                        ) {
                          await remove(i.id);
                          refresh();
                        }
                      }}
                    >
                      削除
                    </Button>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <Modal
        closeOnOverlayClick={false}
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            アカウントの{isEditAction ? "編集" : "作成"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={{ base: 20, md: 5 }}>
            <form name="user_form" onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormControl id="id">
                <Input type="hidden" value={id} />
              </FormControl>
              <FormControl
                id="email"
                isRequired
                isInvalid={errors.email ? true : false}
                mt={4}
              >
                <FormLabel>ID</FormLabel>
                <Input
                  autoComplete="off"
                  maxLength={128}
                  placeholder="test@example.com"
                  {...register("email", {
                    required: "必須項目です",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "メールアドレス形式で入力してください",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              {formPwdInput()}
              <FormControl
                id="person"
                isRequired
                isInvalid={errors.person ? true : false}
                mt={4}
              >
                <FormLabel>person</FormLabel>
                <Input
                  maxLength={20}
                  placeholder="お名前（４文字以上）"
                  {...register("person", {
                    required: "必須項目です",
                    minLength: {
                      value: 2,
                      message: "４文字以上を指定して下さい",
                    }, // 初期設定の名前が4文字以下だった・・・
                    pattern: {
                      value: /^([^\s])(.*)([^\s])$/,
                      message: "前後に空文字が含まれています",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.person && errors.person.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="department"
                isRequired
                isInvalid={errors.department ? true : false}
                mt={4}
              >
                <FormLabel>department</FormLabel>
                <Select
                  {...register("department", {
                    required: "必須項目です",
                  })}
                >
                  {departmentOptions(
                    signInUser.department
                      ? signInUser.department
                      : SystemConst.AdminDepartment,
                  ).map((el, index) => (
                    <option value={el.value} key={index}>
                      {el.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {errors.department && errors.department.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="role"
                isRequired
                isInvalid={errors.role ? true : false}
                mt={4}
              >
                <FormLabel>role</FormLabel>
                <Select
                  {...register("role", {
                    required: "必須項目です",
                  })}
                >
                  {signInUser.role === SystemConst.UserTypeAdmin ? (
                    RoleOptionAry.map((el, index) => (
                      <option value={el.value} key={index}>
                        {el.name}
                      </option>
                    ))
                  ) : (
                    <option value={SystemConst.UserTypeUser}>
                      {SystemConst.UserTypeUser}
                    </option>
                  )}
                </Select>
                <FormErrorMessage>
                  {errors.role && errors.role.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="status"
                isRequired
                isInvalid={errors.status ? true : false}
                mt={4}
              >
                <FormLabel>status</FormLabel>
                <Select
                  {...register("status", {
                    required: "必須項目です",
                  })}
                >
                  {signInUser.role === SystemConst.UserTypeAdmin ? (
                    StatusOptionAry.map((el, index) => (
                      <option value={el.value} key={index}>
                        {el.name}
                      </option>
                    ))
                  ) : (
                    <option value={signInUser.status}>
                      {signInUser.status}
                    </option>
                  )}
                </Select>
                <FormErrorMessage>
                  {errors.status && errors.status.message}
                </FormErrorMessage>
              </FormControl>
              <Button
                type="submit"
                colorScheme="blue"
                mt={10}
                //disabled={!formState.isValid}
                isLoading={formState.isSubmitting}
                spinner={<Spinner color="white.500" />}
              >
                {isEditAction ? "編集" : "作成"}
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Drawer isOpen={isOpenPwd} placement="right" onClose={onClosePwd}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Password 変更</DrawerHeader>
          <DrawerBody>
            <Stack spacing="24px">
              <Box>
                <FormControl
                  id="newPassword"
                  isRequired
                  isInvalid={newPasswordError ? true : false}
                  mt={4}
                >
                  <FormLabel htmlFor="newPassword">新しいPassword</FormLabel>
                  <InputGroup size="md">
                    <Input
                      value={newPassword}
                      name="newPassword"
                      pr="4.5rem"
                      type={showNewPwd ? "text" : "password"}
                      placeholder="Enter password"
                      maxLength={16}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClickNewPwd}>
                        {showNewPwd ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{newPasswordError}</FormErrorMessage>
                </FormControl>
              </Box>
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button
              colorScheme="blue"
              onClick={async () => {
                changeNewPassword();
              }}
            >
              変更
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
}
