import { useRequest } from "ahooks";
import {
  Flex,
  Spacer,
  Button,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  CircularProgress,
  Td,
  TableCaption,
  VStack,
  StackDivider,
  Box,
  Tooltip,
  Icon,
  HStack,
  Center,
} from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "./AuthContextProvider";
import {
  IAnnouncement,
  IAnnouncementBody,
  IAnnouncementWithTitle,
  SystemConst,
  AreaHash,
  Operations,
  ContentsTitleKeyHash,
  returnStringValue,
  periodFormatText,
  isValidPeriod,
  nowDateYMDHM_Szero,
  NexcoBranchEventAreaInfo,
  DwCustomerCodeHash,
  convertDateUTC,
  isEventAreaDepartment,
} from "./SystemConst";
import { ListRefreshButton } from "./partial/ListRefreshButton";
import { InputContentsTitle } from "./partial/InputContentsTitle";
import { AnnouncementModalRegForm } from "./partial/AnnouncementModalRegForm";
//import { MyConfirmDialog } from "./partial/MyConfirmDialog";
import { InfoIcon } from "@chakra-ui/icons";

export function AnnouncementList() {
  const apiPath = "/api/announcement";
  const [user] = useContext(UserContext);
  if (!user) {
    throw Error("user is not null");
  }
  // アカウントが Admin権限（WNI）かどうか
  const isAdminDepartment: boolean =
    user.department === SystemConst.AdminDepartment ? true : false;
  const { isOpen, onOpen, onClose } = useDisclosure();

  
  /**
   * 一覧取得
   */
  const { data, loading, refresh } = useRequest<IAnnouncementWithTitle, []>(
    (): Promise<IAnnouncementWithTitle> =>
      fetch(`${apiPath}/department/${user.department}`).then((x) => x.json()),
  );

  // 登録
  const { run } = useRequest(
    (params: IAnnouncementBody): Promise<void> =>
      fetch(`${apiPath}/withhistory`, {
        method: "POST",
        body: JSON.stringify({
          title: params.title,
          url: params.url,
          area: params.area,
          shareStatus: params.shareStatus,
          shares: params.shares,
          event_area: (isEventAreaDepartment(params.area)) ? (params.event_area) : (0), // add 20240510 NEXCO３社連携
          importance: (isEventAreaDepartment(params.area)) ? (params.importance) : (0), // add 20240510 NEXCO３社連携
          fromDate: params.fromDate,
          toDate: params.toDate,
          department: user.department,
          person: user.person,
          operation: Operations.CREATE,
        }),
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": user.token,
        },
      }).then(refresh),
    { manual: true },
  );

  // 更新
  const { run: update } = useRequest(
    (id: number, params: IAnnouncementBody): Promise<void> =>
      fetch(`${apiPath}/withhistory/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          title: params.title,
          url: params.url,
          area: params.area,
          shareStatus: params.shareStatus,
          shares: params.shares,
          event_area: (isEventAreaDepartment(params.area)) ? (params.event_area) : (0), // add 20240510 NEXCO３社連携
          importance: (isEventAreaDepartment(params.area)) ? (params.importance) : (0), // add 20240510 NEXCO３社連携
          fromDate: params.fromDate,
          toDate: params.toDate,
          department: user.department,
          person: user.person,
          operation: Operations.EDIT,
        }),
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": user.token,
        },
      }).then(refresh),
    { manual: true },
  );

  const requestFunc = async (updateId: number, params: IAnnouncementBody) => {
    // test
    //return new Promise<void>(function(resolve) { resolve(); });

    if (updateId) {
      update(updateId, params);
    } else {
      run(params);
    }
  };

  /**
   * 削除の確認Dialog
   */
  /*
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [deleteId, setDeleteId] = useState(-1);
  const showConfirmDialog = (
    id: number,
    msg: string
  ) => {
    setDeleteId(id)
    setConfirmMessage(msg)
    setIsConfirmOpen(true)
  }
  const confirmCancel = (
  ) => {
    setIsConfirmOpen(false);
    setDeleteId(-1)
  }
  const confirmOk = (  
  ) => {
    console.info("OK[" + deleteId + "]");
    setIsConfirmOpen(false);
    if(deleteId >= 0){
      remove(deleteId)
      refresh()
    }
    setDeleteId(-1)
  }
  */
  /**
   * 削除
   */
  const { run: remove } = useRequest(
    (id: number): Promise<void> =>
      fetch(`${apiPath}/withhistory/${id}`, {
        method: "DELETE",
        body: JSON.stringify({
          department: user.department,
          person: user.person,
          operation: Operations.DELETE,
        }),
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": user.token,
        },
      }).then(refresh),
    { manual: true },
  );

  /**
   * コンテンツタイトル編集
   */
  const [contentsTitle, setContentsTitle] = useState("");
  const { run: updateTitle } = useRequest(
    (): Promise<any> =>
      fetch(`/api/contents-title/withhistory/${ContentsTitleKeyHash["INFO"]}`, {
        method: "PUT",
        body: JSON.stringify({
          //        title: contentsTitle
          idType: ContentsTitleKeyHash["INFO"],
          title: contentsTitle,
          department: user.department,
          person: user.person,
          operation: Operations.EDIT,
        }),
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": user.token,
        },
      }).then((x) => x.json()),
    {
      manual: true,
    },
  );

  const showContentsTitleElement = () => {
    return isAdminDepartment ? (
      <InputContentsTitle
        param={{
          titleText: "お知らせ",
          contentsTitle: contentsTitle,
          inputMaxLen: 14,
          func_setContentsTitle: setContentsTitle,
          func_updateTitle: updateTitle,
        }}
      />
    ) : (
      <></>
    );
  };

  // 情報重要度を返す
  const createImportance = (area: string, imp: number, eventArea: number, isValid: boolean) => {
    if(!eventArea){
      // 事象発生エリア指定がされていない
      // 対象外の会社 or 象外の会社だが、NEXCO３社対応前に登録したデータ
      return ('-')
    }else{
      let impColor: string = "gray";
      if (imp === 1) {
        impColor = "red";
      } else if (imp === 2) {
        impColor = "orange";
      } else if (imp === 3) {
        impColor = "green";
      }

      return (isEventAreaDepartment(area)) ? (
        imp ? (
          <Center
            w="20px"
            h="20px"
            bg={isValid ? `${impColor}.200` : ""}
            color="gray.500"
            fontSize="md"
            fontWeight={isValid ? "bold" : ""}
            borderWidth={isValid ? "3px" : "1px"}
            borderColor={`${impColor}.300`}
            borderRadius={"4"}
          >
            {imp}
          </Center> 
        ) : (
          '-'
        )
      ) : (
        '-'
      )
    
    }
  };
  // 事象発生エリアの名称を返す（複数件の場合は、カンマ + 半角スペース区切り。設定無しの場合は、- ）
  const createEventAreaNameString = (area: string, eventArea: number) => {
    if (isEventAreaDepartment(area)) {
      // 事象発生エリア
      let tmplblAry: string[] = [];
      NexcoBranchEventAreaInfo[area].forEach((item) => {
        if (eventArea & item.mask) {
          tmplblAry.push(item.lbl);
        }
      });
      return tmplblAry.length ? tmplblAry.join(", ") : "-";
    } else {
      return "-";
    }
  };
  // タイトルのTipで各種NEXCO３社連携データ情報を表示する
  const TitleShowIdToolTip = (rowData: IAnnouncement) => {
    if (isEventAreaDepartment(rowData.area)) {
      let tmpUidTxt = "-";
      if (rowData.event_area) {
        const tmpCustCode = DwCustomerCodeHash[rowData.area];
        tmpUidTxt = rowData.createdAt
          ? tmpCustCode + convertDateUTC(rowData.createdAt)
          : "-";
      }

      return (
        <HStack>
          {InfoShareIcon(rowData)}
          {/* add 20240510 NEXCO３社連携 */}
          <Tooltip
            hasArrow
            arrowSize={15}
            bg="blue.300"
            color="black"
            label={
              <Box p={2}>
                <b>UID：</b> [ {tmpUidTxt} ]
              </Box>
            }
            placement="bottom"
          >
            {rowData.title}
          </Tooltip>
        </HStack>
      );
    } else {
      return <>{rowData.title}</>;
    }

    // return isAdminDepartment ? (
    //   <Box>
    //     {InfoShareIcon(rowData)}
    //     <Tooltip
    //       hasArrow
    //       label={rowData.id}
    //       placement="top-start"
    //     >
    //       {rowData.title}
    //     </Tooltip>
    //   </Box>
    // ) : (
    //   <Box>
    //     {InfoShareIcon(rowData)}
    //     {rowData.title}
    //   </Box>
    // );
  };
  // お知らせに情報連携設定がされているか示すアイコン設定
  const InfoShareIcon = (rowData: IAnnouncement) => {
    if (rowData.shareStatus === 0) {
      return <></>;
    }

    const res = isValidPeriod(rowData.fromDate, rowData.toDate);
    let colorStr = "gray.400";
    let labelStr = "情報連携（掲載期間終了）";
    if (res === 1) {
      colorStr = "red.600";
      labelStr = "情報連携 有効";
    } else if (res === -1) {
      colorStr = "blue.400";
      labelStr = "情報連携（予定：掲載期間前）";
    }

    return (
      <Tooltip hasArrow label={labelStr} placement="right" bg={colorStr}>
        <Icon as={InfoIcon} color={colorStr} mr="2" />
      </Tooltip>
    );
  };

  /**
   * 登録 / 更新 Modal 初期画像 設定
   */
  const initRegProps: IAnnouncement = {
    id: 0, // Create:0
    title: "",
    url: "",
    // eslint-disable-next-line
    area:
      user.department === SystemConst.AdminDepartment
        ? "WEST"
        : user.department,
    shareStatus: 0, // 0:連携対象の拠点なし
    shares: [], // 連携対象の拠点Code配列
    event_area: 0, // add 20240510 NEXCO３社連携 （Default: 0: 選択エリア無し）
    importance: (isEventAreaDepartment(user.department)) ? (2) : (0), // add 20240510 NEXCO３社連携 （Default: 2: 重要度2）
    fromDate: nowDateYMDHM_Szero(), // fromDate: new Date(),
    toDate: undefined,
    createdAt: undefined, // NEXCO３社連携でUID作成用に取得する
  };
  const [regProps, setRegProps] = useState<IAnnouncement>(initRegProps);
  const handleOnOpen = (pa: IAnnouncement) => {
    // set local locale
    pa.fromDate = new Date(pa.fromDate);
    pa.toDate = pa.toDate ? new Date(pa.toDate) : pa.toDate;
    pa.importance = pa.importance ? pa.importance : 2; // add 20240510 NEXCO３社連携 （Default: 2: 重要度2）旧データ更新時対応
    setRegProps(pa);
    onOpen();
  };

  useEffect(() => {
    if (data?.title) {
      setContentsTitle(returnStringValue(data?.title));
    }
  }, [data]);

  // 一覧の行を生成
  const createRow = (rowData: IAnnouncement) => {
    const res = periodFormatText(rowData.fromDate, rowData.toDate);
    return (
      <Tr key={rowData.id} color={res.colorState}>
        <Td pr={10} lineHeight={"1.5em"} wordBreak={"break-all"}>
          <VStack
            divider={<StackDivider borderColor="gray.300" />}
            align="stretch"
          >
            <Box>{TitleShowIdToolTip(rowData)}</Box>
            <Box>{rowData.url}</Box>
          </VStack>
        </Td>
        {user.department === SystemConst.AdminDepartment ? (
          <Td p={2}>{AreaHash[rowData.area]}</Td>
        ) : (
          <></>
        )}
        <Td p={2}>{createImportance(rowData.area, rowData.importance, rowData.event_area, res.isValid)}</Td>
        <Td p={2} wordBreak={"break-all"}>
          {createEventAreaNameString(rowData.area, rowData.event_area)}
        </Td>
        <Td p={2}>
          <Box>{res.fromDateFormat} 〜</Box>
          <Box>{res.toDateFormat}</Box>
        </Td>
        <Td p={2}>
          <Button
            size="xs"
            onClick={() => {
              handleOnOpen(rowData);
            }}
            colorScheme="teal"
            variant="ghost"
          >
            編集
          </Button>
        </Td>
        <Td p={2}>
          <Button
            size="xs"
            onClick={async () => {
              if (window.confirm("[ " + rowData.title + " ] を削除します")) {
                remove(rowData.id);
                refresh();
              }
            }}
            colorScheme="teal"
            variant="ghost"
          >
            削除
          </Button>
          {/*
          <Button size='xs'
            onClick={ () => {
              showConfirmDialog( i.id, '[ ' + i.title + ' ] を削除します' )
            }}
            colorScheme="teal"
            variant="ghost"
          >
            削除
          </Button>
          */}
        </Td>
      </Tr>
    );
  };

  // 登録・編集フォーム（Modal）を閉じる時は内容をリセットする
  const resetRegFormClose = () => {
    setRegProps(initRegProps);
    onClose();
  };

  if (loading) {
    return <CircularProgress isIndeterminate color="green.300" />;
  }

  return (
    <Box>
      <Flex alignItems="center">
        <Button
          mx={4}
          colorScheme="blue"
          onClick={() => {
            handleOnOpen(initRegProps);
          }}
        >
          作成
        </Button>
        <Spacer />
        {showContentsTitleElement()}
        <Spacer />
        <ListRefreshButton
          func={async () => {
            refresh();
          }}
        />
      </Flex>
      <Table variant="striped" size="sm">
        <TableCaption>お知らせ リスト</TableCaption>
        <Thead>
          <Tr>
            <Th p={2} minW={300}>
              タイトル / URL
            </Th>
            {user.department === SystemConst.AdminDepartment ? (
              <Th p={2} w={130}>
                エリア
              </Th>
            ) : (
              <></>
            )}
            <Th p={2} w={110}>
              情報重要度
            </Th>
            <Th p={2} minW={200}>
              事象発生エリア
            </Th>
            <Th p={2} w={180}>
              掲載期間
            </Th>
            <Th p={2} w={100}>
              編集
            </Th>
            <Th p={2} w={100}>
              削除
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data && data.list.map((i: IAnnouncement) => createRow(i))}
        </Tbody>
      </Table>
      <AnnouncementModalRegForm
        param={{
          props: regProps,
          isOpenFlag: isOpen,
          userDepartment: user.department,
          funcRegAction: requestFunc,
          //funcClose: onClose,
          funcClose: resetRegFormClose,
          funcRefresh: refresh,
        }}
      />
      {/*
      <MyConfirmDialog
        isOpen={isConfirmOpen}
        validMessage={confirmMessage}
        handleOnCancelClose={confirmCancel}
        handleOnOkClose={confirmOk}
      />
      */}
    </Box>
  );
}
